import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { DetailToko } from '../../../../../Vendor/types';

interface DetailTokoState {
    dataDetailToko: DetailToko;
    loading: boolean;
    error: string | null;
}

const initialState: DetailTokoState = {
    dataDetailToko: {
      _id: '',
      namaToko: '',
      pengikut: 0,
      nilai_review: 0,
      store_description: '',
      profile_pict: '',
      address: {
        address_description: '',
        regency: '',
        province: '',
        code_pos: 0,
      },
      waktu_operasional: []
    },
    loading: false,
    error: null,
};

export const fetchDetailToko = createAsyncThunk<DetailToko, void>('DetailToko/fetchDetailToko', async () => {
    try {
        const response = await axiosInstance.get(`/vendor/detail/my-store`);
        console.log('response detail toko vendor ', response.data.data);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching detail toko vendor:', error);
        throw error;
    }
});

const TokoVendorSlice = createSlice({
    name: 'tokovendor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailToko.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDetailToko.fulfilled, (state, action: PayloadAction<DetailToko>) => {
                state.loading = false;
                state.dataDetailToko = action.payload;
            })
            .addCase(fetchDetailToko.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch detail toko vendor';
                console.error('Error fetching detail:', action.error.message);
            });
    },
});

export const TokoVendorService = { action: TokoVendorSlice.actions, fetchDetailToko };
const tokoVendorReducer = TokoVendorSlice.reducer;
export default tokoVendorReducer;
