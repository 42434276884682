import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { SubscriptionListProp } from '../../../../../konsumnen/pages/profile/menu/MyProfile/TokoFavorit/section/subscriptionList/interface';

export const GetSubscriptionList = createAsyncThunk(
  'subscription/list',
  async () => { 
    const response = await axiosInstance.get(`/konsumen/toko-favorit`);
    return response.data;
  }
);

// Redux slice
const StoreSubscriptionList = createSlice({
    name: 'StoreSubscriptionList',
    initialState: {
      data: null as SubscriptionListProp | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetSubscriptionList.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(GetSubscriptionList.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(GetSubscriptionList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default StoreSubscriptionList.reducer;
