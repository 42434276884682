import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListAlamat } from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';

interface AlamatState {
    listAlamat: ListAlamat[];
    loading: boolean;
    error: string | null;
}

const initialState: AlamatState = {
    listAlamat: [],
    loading: false,
    error: null,
};

export const fetchListAlamat = createAsyncThunk<ListAlamat[], void>('ListAlamat/fetchListAlamat', async () => {
    try {
        const response = await axiosInstance.get(`/address/list`);
        console.log('response list alamat ', response.data.datas);
        return response.data.datas;
    } catch (error) {
        console.error('Error fetching list alamat:', error);
        throw error;
    }
});

const AlamatSlice = createSlice({
    name: 'alamatDistri',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchListAlamat.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchListAlamat.fulfilled, (state, action: PayloadAction<ListAlamat[]>) => {
                state.loading = false;
                state.listAlamat = action.payload;
            })
            .addCase(fetchListAlamat.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch list alamat';
                console.error('Error fetching list alamat:', action.error.message);
            });
    },
});

export const AlamatService = { action: AlamatSlice.actions, fetchListAlamat };
const alamatReducer = AlamatSlice.reducer;
export default alamatReducer;
