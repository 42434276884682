import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../api/axiosInstance';
import { DistributorDetail, DistributorHome } from '../../../../distributor/types';

interface DistributtorState {
    dataDistributor: DistributorDetail;
    loading: boolean;
    error: string | null;
    dataDistributorHome: DistributorHome;
}

const initialState: DistributtorState = {
    dataDistributor: {
        perusahaan: [],
        alamat_id: {
            pinAlamat: {
                lat: {
                    $numberDecimal: '',
                },
                long: {
                    $numberDecimal: '',
                },
            },
        },
        imageProfile: '',
        _id: '',
        jenisKelamin: '',
        nama_distributor: '',
        userId: {
            isDetailVerified: false,
            phone: {
                content: 0,
                isVerified: false,
            },
            role: '',
            email: {
                isVerified: false,
                content: '',
            },
            poin: 0,
        },
        individu: [],
        jenisPerusahaan: '',
        tanggal_lahir: '',
    },
    dataDistributorHome: {
        total_pesanan: 0,
        kenaikanPesanan: '',
        total_dijemput: 0,
        statusKenaikan: false,
        totalPesananHariIni: 0,
        total_dibatalkan: 0,
    },
    loading: false,
    error: null,
};

export const fetchDistributorDetail = createAsyncThunk<DistributorDetail, void>(
    'DistributorDetail/fetchDistributorDetail',
    async () => {
        try {
            const response = await axiosInstance.get(`/distributor/detail`);
            console.log('ini response distributor ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching distributor:', error);
            throw error;
        }
    },
);
export const fetchDistributor = createAsyncThunk<DistributorHome, void>('Distributor/fetchDistributor', async () => {
    try {
        const response = await axiosInstance.get(`/distributor/detail`);
        console.log('ini response distributor ', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching distributor:', error);
        throw error;
    }
});
export const updateDistributtor = createAsyncThunk<DistributorDetail, { formData: FormData }>(
    'DistributorDetail/updateDistributtor',
    async ({ formData }) => {
        try {
            const response = await axiosInstance.put(`/distributor/update`, formData);
            console.log('response distributor ', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching distributor:', error);
            throw error;
        }
    },
);

const DistributtorSlice = createSlice({
    name: 'userVerif',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDistributorDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDistributorDetail.fulfilled, (state, action: PayloadAction<DistributorDetail>) => {
                state.loading = false;
                state.dataDistributor = action.payload;
            })
            .addCase(fetchDistributorDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch detail distri';
                console.error('Error fetching detail:', action.error.message);
            })
            .addCase(fetchDistributor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDistributor.fulfilled, (state, action: PayloadAction<DistributorHome>) => {
                state.loading = false;
                state.dataDistributorHome = action.payload;
            })
            .addCase(fetchDistributor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch detail distri';
                console.error('Error fetching detail:', action.error.message);
            });
    },
});

export const DistributtorService = { action: DistributtorSlice.actions, fetchDistributorDetail };
const distributtorReducer = DistributtorSlice.reducer;
export default distributtorReducer;
