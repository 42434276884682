import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreatePengemudiPerusahaan, PengemudiList } from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';

interface ManajemenPengemudiState {
    pengemudiData: PengemudiList[];
    createPengemudi: CreatePengemudiPerusahaan[];
    loading: boolean;
    error: string | null;
}

const initialState: ManajemenPengemudiState = {
    createPengemudi: [],
    pengemudiData: [],
    loading: false,
    error: null,
};

export const fetchPengemudiData = createAsyncThunk<PengemudiList[], void>(
    'PengemudiData/fetchPengemudiData',
    async () => {
        try {
            const response = await axiosInstance.get(`/pengemudi/list`);
            console.log('response data pengemudi', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching pengemudi list:', error);
            throw error;
        }
    },
);

export const addPengemudi = createAsyncThunk<CreatePengemudiPerusahaan, FormData>(
    'pengemudidata/addPengemudi',
    async (formData) => {
        try {
            const response = await axiosInstance.post('/pengemudi/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response dari server:', response.data.data);
            return response.data.data;
        } catch (error: any) {
            console.error('Error saat mengunggah data:', error);
            throw new Error(error.response?.data?.message || 'Gagal mengunggah data');
        }
    },
);

export const updatePengemudi = createAsyncThunk<PengemudiList, { id: string; formData: FormData }>(
    'pengemudidata/updatePengemudi',
    async ({ id, formData }) => {
        try {
            const response = await axiosInstance.put(`/pengemudi/update/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.data;
        } catch (error: any) {
            throw new Error(error.response?.data?.message || 'Gagal mengupdate data');
        }
    },
);

const ManajemenPengemudiSlice = createSlice({
    name: 'manajemenPengemudi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchPengemudiData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPengemudiData.fulfilled, (state, action: PayloadAction<PengemudiList[]>) => {
                state.loading = false;
                state.pengemudiData = action.payload;
            })
            .addCase(fetchPengemudiData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch pengemudi data';
                console.error('Error fetching pengemudi data:', action.error.message);
            })
            .addCase(addPengemudi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addPengemudi.fulfilled, (state, action: PayloadAction<CreatePengemudiPerusahaan>) => {
                state.loading = false;
                console.log('State addPengemudi sebelum push:', state.createPengemudi); // Tambahkan log ini
                state.createPengemudi.push(action.payload);
            })
            .addCase(addPengemudi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to create pengemudi';
                console.error('Error creating pengemudi:', action.error.message);
            })
            .addCase(updatePengemudi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePengemudi.fulfilled, (state, action: PayloadAction<PengemudiList>) => {
                state.loading = false;
                const index = state.pengemudiData.findIndex((pengemudi) => pengemudi._id === action.payload._id);
                if (index !== -1) {
                    state.pengemudiData[index] = action.payload; // Update data pengemudi yang ada
                }
            })
            .addCase(updatePengemudi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update pengemudi';
            });
    },
});

export const ManajemenPengemudiService = { action: ManajemenPengemudiSlice.actions, fetchPengemudiData };
const manajemenPengemudiReducer = ManajemenPengemudiSlice.reducer;
export default manajemenPengemudiReducer;
