import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { subscriptionRekomendationProp } from '../../../../../konsumnen/pages/profile/menu/MyProfile/TokoFavorit/section/rekomendation/interface';

export const GetRekomendationSubscription = createAsyncThunk(
  'subscription/rekomendation',
  async () => { 
    const response = await axiosInstance.get(`/konsumen/toko-rekomendasi`);
    return response.data;
  }
);

// Redux slice
const StoreSubscriptionRekomendation = createSlice({
    name: 'StoreSubscriptionRekomendation',
    initialState: {
      data: null as subscriptionRekomendationProp | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetRekomendationSubscription.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(GetRekomendationSubscription.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(GetRekomendationSubscription.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default StoreSubscriptionRekomendation.reducer;
