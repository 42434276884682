import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { WhistlistProp } from '../../../../../konsumnen/pages/profile/menu/daftarKeinginan/interface';
export const GetWhistList = createAsyncThunk(
  'whistlist',
  async () => { 
    const response = await axiosInstance.get(`/wishlist/list`);     
    return response.data;
  }
);

// Redux slice
const Whistlist = createSlice({
    name: 'Whistlist',
    initialState: {
      data: null as WhistlistProp | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetWhistList.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(GetWhistList.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(GetWhistList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default Whistlist.reducer;
