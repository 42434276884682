import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { LoginActivity } from '../../../../../konsumnen/pages/profile/section/Keamanan/menu/aktifitasLogin/interface';

export const GetLoginActivity = createAsyncThunk('login/activity', async () => {
    const response = await axiosInstance.get(`user/login-activities`);
    return response.data;
});

// Redux slice
const DevicesLoginList = createSlice({
    name: 'DevicesLoginList',
    initialState: {
        data: null as LoginActivity | null,
        loading: false,
        error: null as string | null | undefined,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetLoginActivity.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetLoginActivity.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(GetLoginActivity.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default DevicesLoginList.reducer;
