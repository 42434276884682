import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KonsumenDetail } from '../../../../../konsumnen/pages/home/interface';
interface ResponseDataState {
    data: KonsumenDetail | null;
}

const initialState: ResponseDataState = {
    data: null,
};

const responseDataSlice = createSlice({
    name: 'responseData',
    initialState,
    reducers: {
        setResponseData(state, action: PayloadAction<KonsumenDetail>) {
            state.data = action.payload;
        }
    }
});

export const { setResponseData } = responseDataSlice.actions;

export default responseDataSlice.reducer;
