import React from 'react'

export default function LoadingComponent() {
    return (
        <main className='flex flex-col w-full justify-center items-center h-[100vh]'>
            <svg
                viewBox="25 25 50 50"
                className="w-[3.25em] origin-center animate-rotate4"
            >
                <circle
                    r="20"
                    cx="50"
                    cy="50"
                    className="fill-none stroke-[hsl(214,97%,59%)] stroke-[2] stroke-dasharray-[1,200] strokeLinecap-round animate-dash4"
                />
            </svg>
            <p className='mt-[8px]'>Loading...</p>
        </main>
    )
}
